/**/

#bg-layer {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
}

hr {
  opacity: 0.4;
}

.unselectable-span {
  user-select: none;
}

.hide-on-demand {
  display: none !important;
}

.disabled {
  opacity: 0.4;
  cursor: hand;
  pointer-events: none;
}

.pre-line-content {
  white-space: pre-wrap;
}

.algo-card {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;
  background-color: #eedaff;
  padding: 16px;
  border-radius: 10px;
  border: 2px solid #d5a1ff;
}

.algo-button-preselected {
  background-color: #d5e0ff;
  border-radius: 25px;
  border: 2px solid #b5c9ff;
} 